import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getAllData = createAsyncThunk(
  "companyUsers/getAllData",
  async (_, { getState }) => {
    const { companyUsers } = getState()
    const response = await axios.get("/companies/users", {
      params: companyUsers?.params
    })
    return response.data
  }
)
export const getDataById = createAsyncThunk("companies/getDataById", async (id) => {
  const response = await axios.get(`/companies/users/${id}`)
  return response?.data
})

export const getAllCompany = createAsyncThunk(
  "companyUsers/getAllCompany",
  async () => {
    const response = await axios.get("/options/company")
    return response.data
  }
)
export const getAllRole = createAsyncThunk(
  "companyUsers/getAllRole",
  async (company_id) => {
    const response = await axios.get(`/options/role/${company_id}`)
    return response.data
  }
)
export const getAllDesignation = createAsyncThunk(
  "companyUsers/getAllDesignation",
  async (company_id) => {
    const response = await axios.get(`/options/designation/${company_id}`)
    return response.data
  }
)
export const getAllDivision = createAsyncThunk(
  "companyUsers/getAllDivision",
  async (company_id) => {
    const response = await axios.get(`/options/division/${company_id}`)
    return response.data
  }
)
export const getAllDepartment = createAsyncThunk(
  "companyUsers/getAllDepartment",
  async (id) => {
    const response = await axios.get(`/options/department/${id}`)
    return response.data
  }
)
export const getAllUser = createAsyncThunk(
  "companyUsers/getAllUser",
  async (id) => {
    const response = await axios.get(`/options/user/${id}`)
    return response.data
  }
)

export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (user, { dispatch }) => {
    const res = await axios.post("/employees", user)
    if (res?.status === 200 || res?.status === 201) {
      dispatch(getAllData())
      return { success: true }
    } else {
      return { success: false, error: res?.response?.data }
    }
  }
)
export const updateUser = createAsyncThunk(
  "appUsers/updateUser",
  async (data, { dispatch }) => {
    const res = await axios.post(
      `/update-employees/${data?.id}`,
      data?.updateData
    )
    await dispatch(getAllData())
    return res
  }
)
export const getData = createAsyncThunk("appUsers/getData", async (params) => {
  const response = await axios.get(`/employee`)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})
export const getUser = createAsyncThunk("appUsers/getUser", async (id) => {
  const response = await axios.get(`/employees-getuser/${id}`)
  return response
})

export const getUserData = createAsyncThunk(
  "appUsers/getUserData",
  async (id) => {
    const response = await axios.get(`/employees-getuserdata/${id}`)
    return response
  }
)

export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/users/delete", { id })
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return id
  }
)

export const downloadExcelSheet = createAsyncThunk(
  "appUsers/downloadExcelSheet",
  async (param) => {
    const response = await axios.get("export/user", {
      params: param,
      responseType: "blob"
    })

    const outputFilename = "userlist.xlsx"
    const url = URL.createObjectURL(new Blob([response?.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", outputFilename)
    document.body.appendChild(link)
    link.click()

    // OR you can save/write file locally.
    fs.writeFileSync(outputFilename, response?.data)
  }
)

export const companyUsersSlice = createSlice({
  name: "companyUsers",
  initialState: {
    permission: {},
    data: [],
    total: 1,
    loading: false,

    params: {},
    allData: [],
    selectedUser: null,
    selectedUserData: null,
    
    companyOptions: [],
    userOptions: [],
    designationOptions: [],
    roleOptions: [],
    divisionOptions: [],
    departmentOptions: [],
    statusOptions: [
      { value: 1, label: "Intern" },
      { value: 2, label: "Probation" },
      { value: 3, label: "Permanent" },
      { value: 4, label: "Contractual" },
      { value: 5, label: "Terminated" },
      { value: 6, label: "Ex-Employee" }
    ]
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action.payload?.users.data
        state.permission = action.payload?.permission
        state.total = action.payload?.users?.total
        state.current =
          action.payload?.users?.to - action.payload?.users?.from + 1
        state.to = action.payload?.users?.to
        state.from = action.payload?.users?.from
      })
      .addCase(getAllCompany.fulfilled, (state, action) => {
        state.companyOptions = action.payload
      })
      .addCase(getAllRole.fulfilled, (state, action) => {
        state.roleOptions = action.payload
      })
      .addCase(getAllDesignation.fulfilled, (state, action) => {
        state.designationOptions = action.payload
      })
      .addCase(getAllDivision.fulfilled, (state, action) => {
        state.divisionOptions = action.payload
      })
      .addCase(getAllDepartment.fulfilled, (state, action) => {
        state.departmentOptions = action.payload
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        state.userOptions = action.payload
      })

      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload?.data
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.selectedUserData = action.payload?.data
      })
  },
  reducers: {
    setParams: (state, { payload }) => {
      state.params = { ...state.params, ...payload }
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    }
  }
})
export const { setParams, setLoading } = companyUsersSlice.actions

export default companyUsersSlice.reducer
