// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getAllData = createAsyncThunk(
  "companies/getAllData",
  async (_, { getState }) => {
    const { companies } = getState()
    const response = await axios.get("companies/company", { params: companies?.params })
    return response.data
  }
)
export const getDataById = createAsyncThunk("companies/getDataById", async (id) => {
  const response = await axios.get(`companies/company/${id}`)
  return response?.data
})
export const updateData = createAsyncThunk(
  "companies/updateData",
  async (data, { dispatch }) => {
    const res = await axios.patch(
      `/company/${data?.id}`,
      data?.data
    )
    await dispatch(getAllData())
    return res
  }
)
export const storeData = createAsyncThunk(
  "companies/storeData",
  async (data, { dispatch }) => {
    const res = await axios.post("/company", data)
    if (res?.status === 200 || res?.status === 201) {
      dispatch(getAllData())
      return { success: true }
    } else {
      return { success: false, error: res?.response?.data?.errors }
    }
  }
)


export const getAllRole = createAsyncThunk("appUsers/getAllRole", async () => {
  const response = await axios.get("employees-roles")
  return response.data
})
export const getAllEmployee = createAsyncThunk(
  "appUsers/getAllEmployee",
  async () => {
    const response = await axios.get("employees-employees")
    return response.data
  }
)
export const getAllDesignation = createAsyncThunk(
  "appUsers/getAllDesignation",
  async () => {
    const response = await axios.get("employees-designations")
    return response.data
  }
)
export const getAllDivision = createAsyncThunk(
  "appUsers/getAllDivision",
  async () => {
    const response = await axios.get("employees-divisions")
    return response.data
  }
)
export const getAllDepartment = createAsyncThunk(
  "appUsers/getAllDepartment",
  async (id) => {
    const response = await axios.get(`employees-departments/${id}`)
    return response.data
  }
)
export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (user, { dispatch }) => {
    const res = await axios.post("/employees", user)
    if (res?.status === 200 || res?.status === 201) {
      dispatch(getAllData())
      return { success: true }
    } else {
      return { success: false, error: res?.response?.data }
    }
  }
)
export const updateUser = createAsyncThunk(
  "appUsers/updateUser",
  async (data, { dispatch }) => {
    const res = await axios.post(
      `/update-employees/${data?.id}`,
      data?.updateData
    )
    await dispatch(getAllData())
    return res
  }
)
export const getData = createAsyncThunk("appUsers/getData", async (params) => {
  const response = await axios.get(`/employee`)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})
export const getUser = createAsyncThunk("appUsers/getUser", async (id) => {
  const response = await axios.get(`/employees-getuser/${id}`)
  return response
})

export const getUserData = createAsyncThunk(
  "appUsers/getUserData",
  async (id) => {
    const response = await axios.get(`/employees-getuserdata/${id}`)
    return response
  }
)

export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/users/delete", { id })
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return id
  }
)

export const downloadExcelSheet = createAsyncThunk(
  "appUsers/downloadExcelSheet",
  async (param) => {
    const response = await axios.get("export/user", {
      params: param,
      responseType: "blob"
    })

    const outputFilename = "userlist.xlsx"
    const url = URL.createObjectURL(new Blob([response?.data]))
    const link = document.createElement("a")
    link.href = url
    link.setAttribute("download", outputFilename)
    document.body.appendChild(link)
    link.click()

    // OR you can save/write file locally.
    fs.writeFileSync(outputFilename, response?.data)
  }
)

export const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    permission: {},
    data: [],
    total: 1,
    loading: false,

    params: {},
    allData: [],
    selectedUser: null,
    selectedUserData: null,

    statusOptions: [
      { value: 1, label: "Active" },
      { value: 2, label: "Inactive" },
      { value: 3, label: "Rejected" }
    ],
    subsOptions:[
      {value: 1, label: "Small Team"},
      {value: 2, label: "Medium Team"},
      {value: 3, label: "Large Team"},
      {value: 4, label: "Extra Large Team"}
    ],

    supervisorOptions: [],
    designationOptions: [],
    roleOptions: [],
    divisionOptions: [],
    departmentOptions: []    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action.payload?.companies?.data
        state.permission = action.payload?.permission
        state.total = action.payload?.companies?.total
        state.current =
          action.payload?.companies?.to - action.payload?.companies?.from + 1
        state.to = action.payload?.companies?.to
        state.from = action.payload?.companies?.from
      })

      .addCase(getAllRole.fulfilled, (state, action) => {
        state.roleOptions = action.payload?.map((item) => {
          return { value: item.id, label: item.name }
        })
      })
      .addCase(getAllDesignation.fulfilled, (state, action) => {
        state.designationOptions = action.payload?.map((item) => {
          return { value: item.id, label: item.name }
        })
      })
      .addCase(getAllDivision.fulfilled, (state, action) => {
        state.divisionOptions = action.payload?.map((item) => {
          return { value: item.id, label: item.name }
        })
      })
      .addCase(getAllDepartment.fulfilled, (state, action) => {
        state.departmentOptions = action.payload?.map((item) => {
          return { value: item?.id, label: item.name }
        })
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.supervisorOptions = action.payload?.map((item) => {
          return { value: item.id, label: item.name }
        })
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload?.data
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.selectedUserData = action.payload?.data
      })
  },
  reducers: {
    setParams: (state, { payload }) => {
      state.params = { ...state.params, ...payload }
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    }
  }
})
export const { setParams, setLoading } = companiesSlice.actions

export default companiesSlice.reducer
