// ** Reducers Imports

import global from './globalStore'

//user-management
import permissions from '@src/views/pages/user-management/roles/store'
import users from '@src/views/pages/user-management/user/store'

//kpi
import dashboard from '@src/views/pages/dashboard/store'
import subscriptions from '@src/views/pages/help/PackageUpgrade/store'
import tickets from '@src/views/pages/help/Ticket/store'
import kpiEvaluation from '@src/views/pages/kpi/KPIEvaluation/store'
import kpiWindow from '@src/views/pages/kpi/KPIWindow/store'
import masterSettings from '@src/views/pages/kpi/kpi-settings/master-settings/store'
import periods from '@src/views/pages/kpi/kpi-settings/periods/store'
import kpiProfile from '@src/views/pages/kpi/profile/store'
import windows from '@src/views/pages/kpi/windows/store'

import companies from '@src/views/pages/company/company/store'
import companyUsers from '@src/views/pages/company/user/store'

import ticketType from '@src/views/pages/help/TicketType/store'

import calendar from '@src/views/apps/calendar/store'
import chat from '@src/views/apps/chat/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import kanban from '@src/views/apps/kanban/store'
import todo from '@src/views/apps/todo/store'
import dataTables from '@src/views/tables/data-tables/store'
import auth from './authentication'
import layout from './layout'
import navbar from './navbar'

const rootReducer = {
  auth,
  global,
  dashboard,
  users,
  permissions,
  kpiProfile,
  kpiWindow,
  kpiEvaluation,
  windows,
  masterSettings,
  periods,
  subscriptions,
  
  companies,
  companyUsers,
  
  tickets, 
  ticketType,

  todo,
  chat,
  email,
  kanban,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables
}

export default rootReducer
