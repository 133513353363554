import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const getAllData = createAsyncThunk(
  "tickets/getAllData",
  async (params) => {
    const response = await axios.get("/help/tickets", { params })
    return response.data
  }
)

export const getAllMessage = createAsyncThunk(
  "tickets/getAllMessage",
  async (id) => {
    const response = await axios.get(`/help/tickets/${id}`)
    return response.data
  }
)

export const sendMessage = createAsyncThunk(
  "tickets/sendMessage",
  async (data) => {
    const res = await axios.post("/help/ticket-message", data)
    return res.data
  }
)
export const sendFile = createAsyncThunk("tickets/sendFile", async (data) => {
  const res = await axios.post("/help/ticket-file", data)
  return res.data
})

export const closeTicket = createAsyncThunk(
  "tickets/closeTicket",
  async (id) => {
    const res = await axios.get(`/help/ticket-close/${id}`)
    return res.data
  }
)

export const ticketsSlice = createSlice({
  name: "tickets",
  initialState: {
    permission: {},
    data: [],
    loading: false,

    statusOptions: [
      { label: "Open", value: 1 },
      { label: "Working", value: 2 },
      { label: "Closed", value: 3 }
    ],

    message: null
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action?.payload?.tickets?.data
        state.permission = action?.payload?.permission
        state.total = action?.payload?.tickets?.total
      })
      .addCase(getAllMessage.fulfilled, (state, action) => {
        state.message = action?.payload
      })
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  }
})
export const { setLoading } = ticketsSlice.actions

export default ticketsSlice.reducer
