// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getAllData = createAsyncThunk(
  "appUsers/getAllData",
  async (_, { getState }) => {
    const { users } = getState()
    const response = await axios.get("management/employees", { params: users?.params })
    return response.data
  }
)
export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (user, { dispatch }) => {
    const res = await axios.post("management/employees", user)
    if (res?.status === 200 || res?.status === 201) {
      dispatch(getAllData())
      return { success: true }
    } else {
      return { success: false, error: res?.response?.data }
    }
  }
)
export const updateUser = createAsyncThunk(
  "appUsers/updateUser",
  async (data, { dispatch }) => {
    const res = await axios.patch(
      `management/employees/${data?.id}`,
      data?.updateData
    )
    await dispatch(getAllData())
    return res
  }
)
export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/users/delete", { id })
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return id
  }
)
export const getUser = createAsyncThunk("appUsers/getUser", async (id) => {
  const response = await axios.get(`management/employees/${id}`)
  return response
})

export const getAllRole = createAsyncThunk("appUsers/getAllRole", async () => {
  const response = await axios.get("options/role/0")
  return response.data
})

export const appUsersSlice = createSlice({
  name: "appUsers",
  initialState: {
    permission: {},
    data: [],
    total: 1,
    loading: false,

    params: {},
    allData: [],
    selectedUser: null,
    selectedUserData: null,

    roleOptions: []
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action.payload?.users.data
        state.permission = action.payload?.permission
        state.total = action.payload?.users?.total
        state.current =
          action.payload?.users?.to - action.payload?.users?.from + 1
        state.to = action.payload?.users?.to
        state.from = action.payload?.users?.from
      })
      .addCase(getAllRole.fulfilled, (state, action) => {
        state.roleOptions = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload?.data
      })
  },
  reducers: {
    setParams: (state, { payload }) => {
      state.params = { ...state.params, ...payload }
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    }
  }
})
export const { setParams, setLoading } = appUsersSlice.actions

export default appUsersSlice.reducer
