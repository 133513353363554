// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

export const getAllData = createAsyncThunk(
  "ticketType/getAllData",
  async (_, { getState }) => {
    const { ticketType } = getState()
    const response = await axios.get("help/ticket-type", { params: ticketType?.params })
    return response.data
  }
)
export const getDataById = createAsyncThunk("ticketType/getDataById", async (id) => {
  const response = await axios.get(`help/ticket-type/${id}`)
  return response?.data
})
export const updateData = createAsyncThunk(
  "ticketType/updateData",
  async (data, { dispatch }) => {
    const res = await axios.patch(
      `help/ticket-type/${data?.id}`,
      data?.data
    )
    if (res?.status === 200 || res?.status === 201) {
      dispatch(getAllData())
      return { success: true }
    } else {
      return { success: false, error: res?.response?.data?.errors }
    }
  }
)
export const storeData = createAsyncThunk(
  "ticketType/storeData",
  async (data, { dispatch }) => {
    const res = await axios.post("help/ticket-type", data)
    if (res?.status === 200 || res?.status === 201) {
      dispatch(getAllData())
      return { success: true }
    } else {
      return { success: false, error: res?.response?.data?.errors }
    }
  }
)
export const getAllEmployee = createAsyncThunk(
  "ticketType/getAllEmployee",
  async () => {
    const response = await axios.get("options/admin-user")
    return response.data
  }
)

export const addUser = createAsyncThunk(
  "appUsers/addUser",
  async (user, { dispatch }) => {
    const res = await axios.post("/employees", user)
    if (res?.status === 200 || res?.status === 201) {
      dispatch(getAllData())
      return { success: true }
    } else {
      return { success: false, error: res?.response?.data }
    }
  }
)
export const updateUser = createAsyncThunk(
  "appUsers/updateUser",
  async (data, { dispatch }) => {
    const res = await axios.post(
      `/update-employees/${data?.id}`,
      data?.updateData
    )
    await dispatch(getAllData())
    return res
  }
)
export const getData = createAsyncThunk("appUsers/getData", async (params) => {
  const response = await axios.get(`/employee`)
  return {
    params,
    data: response.data.users,
    totalPages: response.data.total
  }
})
export const getUser = createAsyncThunk("appUsers/getUser", async (id) => {
  const response = await axios.get(`/employees-getuser/${id}`)
  return response
})

export const getUserData = createAsyncThunk(
  "appUsers/getUserData",
  async (id) => {
    const response = await axios.get(`/employees-getuserdata/${id}`)
    return response
  }
)

export const deleteUser = createAsyncThunk(
  "appUsers/deleteUser",
  async (id, { dispatch, getState }) => {
    await axios.delete("/apps/users/delete", { id })
    await dispatch(getData(getState().users.params))
    await dispatch(getAllData())
    return id
  }
)

export const ticketTypeSlice = createSlice({
  name: "ticketType",
  initialState: {
    permission: {},
    data: [],
    total: 1,
    loading: false,

    params: {},
    allData: [],
    selectedUser: null,
    selectedUserData: null,

    usersOptions: []
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.data = action.payload?.types?.data
        state.permission = action.payload?.permission
        state.total = action.payload?.ticketType?.total
        state.current =
          action.payload?.ticketType?.to - action.payload?.ticketType?.from + 1
        state.to = action.payload?.ticketType?.to
        state.from = action.payload?.ticketType?.from
      })
      .addCase(getAllEmployee.fulfilled, (state, action) => {
        state.usersOptions = action.payload
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload?.data
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.selectedUserData = action.payload?.data
      })
  },
  reducers: {
    setParams: (state, { payload }) => {
      state.params = { ...state.params, ...payload }
    },
    setLoading: (state, { payload }) => {
      state.loading = payload
    }
  }
})
export const { setParams, setLoading } = ticketTypeSlice.actions

export default ticketTypeSlice.reducer
